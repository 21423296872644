<template lang="html">
  <div class="p-currency-detail">
    <CommonHeader class="header">
      <nav class="type-switch-bar">
        <span :class="{active: currAction === 'recharge'}" @click="currAction = 'recharge'">
          {{ $t('currency.recharge.log') }}
        </span>
        <span :class="{active: currAction === 'cash'}" @click="currAction = 'cash'">
          {{ $t('currency.withdraw.log') }}
        </span>
      </nav>
    </CommonHeader>

    <JoLoadMore
      ref="loadmore"
      class="m-currency-list"
      @onLoadMore="onLoadMore"
      @onRefresh="onRefresh"
    >
      <CurrencyDetailItem
        v-for="item in list"
        v-if="item.id"
        :key="item.id"
        :detail="item"
        :hasStatus="item.type == -1"
        type="body"
      />
    </JoLoadMore>
  </div>
</template>

<script>
  import _ from 'lodash'
  import CurrencyDetailItem from './components/CurrencyDetailItem.vue'
  import i18n from '@/i18n'

  export default {
    name: 'CurrencyDetail',
    components: { CurrencyDetailItem },
    data () {
      return {
        options: [
          { value: 'all', label: i18n.t('all') },
          { value: 'expenses', label: i18n.t('wallet.expenses') },
          { value: 'income', label: i18n.t('wallet.income') },
        ],
        list: [],
        currInfo: null,
      }
    },
    computed: {
      after () {
        const len = this.list.length
        return len ? this.list[len - 1].id : 0
      },
      currAction: {
        get () {
          return this.$route.query.action || 'recharge'
        },
        set (val) {
          this.$router.replace({
            path: this.$route.path,
            query: { action: val },
          })
        },
      },
    },
    watch: {
      currAction () {
        this.list = []
        this.$refs.loadmore.beforeRefresh()
      },
    },
    methods: {
      onRefresh () {
        this.$store
          .dispatch('currency/getCurrencyOrders', { action: this.currAction })
          .then(data => {
            if (data.length > 0) { this.list = _.unionBy([...data, ...this.list], 'id') }

            this.$refs.loadmore.afterRefresh(data.length < 15)
          })
      },
      onLoadMore () {
        this.$store
          .dispatch('currency/getCurrencyOrders', {
            action: this.currAction,
            after: this.after,
          })
          .then(data => {
            if (data.length > 0) {
              this.list = [...this.list, ...data]
            }
            this.$refs.loadmore.afterLoadMore(data.length < 15)
          })
      },
    },
  }
</script>

<style lang="less" scoped>
.p-currency-detail {
  .header {
    overflow: initial;
  }

  .type-switch-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90px;

    > span {
      display: inline-block;
      height: 100%;
      padding: 22px 12px;
      color: #999;
      transition: 0.3s;

      &.active {
        color: #333;
        border-bottom: 2px solid @primary;
      }
    }
  }
}
</style>
