<template>
  <div class="p-wallet-info">
    <CommonHeader>{{ $t('wallet.info.name') }}</CommonHeader>

    <header class="wallet-header">
      <p v-if="detail.state === 0">{{ $t('wallet.review') }}</p>
      <p v-else>{{ detail.state === 1 ? 'wallet.info.success' : 'wallet.info.failed' | t }}</p>
      <h2>{{ detail.type > 0 ? '+' : '-' }}{{ detail.amount / 100 | postfix(2) }}</h2>
    </header>

    <main>
      <!-- <div v-if="detail.owner_id" class="item">
        <label>{{ detail.type > 0 ? '收款人' : '付款人' }}</label>
        <span class="user-avatar">
          <avatar :user="user"/>
          {{ user.name }}
        </span>
      </div> -->
      <div class="item">
        <label>{{ $t('wallet.info.desc') }}</label>
        <span> {{ detail.body || detail.title }} </span>
      </div>
      <div v-if="detail.target_type==='s'" class="item">
        <label>{{ $t('wallet.info.account') }}</label>
        <span> {{ detail.title }} </span>
      </div>
      <div class="item">
        <label>{{ $t('wallet.info.time') }}</label>
        <span> {{ detail.created_at | formatDate }} </span>
      </div>
    </main>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'WalletInfo',
    filters: {
      postfix (val, pos) {
        if (!val) return '0.00'
        return val.toFixed(pos)
      },
    },
    data () {
      return {
      // detail: {}
      }
    },
    computed: {
      ...mapState({ wallet: 'wallet' }),
      id () {
        return Number(this.$route.params.id)
      },
      detail () {
        return this.$store.getters['wallet/getWalletById'](this.id)
      },
      user () {
        return this.$store.state.CURRENTUSER
      },
    },
    mounted () {
      if (!this.wallet.list.length) { this.$store.dispatch('wallet/getWalletOrders') }
    },
    methods: {},
  }
</script>

<style lang="less" scoped>
.p-wallet-info {
  .wallet-header {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #fff;
    padding: 40px 20px;

    > p {
      font-size: 28px;
      color: #999;
    }

    > h2 {
      font-size: 100px;
    }
  }
  main {
    margin-top: 20px;
    background: #fff;

    > .item {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-top: 1px solid #ededed; /* no */

      > span {
        width: calc(100% - 6em);
        padding: 20px 0;
      }

      &:first-child {
        border-top: none;
      }

      label {
        width: 6em;
        font-size: 30px;
        color: #999;
        padding: 0 1em;
      }

      .user-avatar {
        display: flex;
        align-items: center;
        height: 80%;
      }
    }
  }
}
</style>
