<template lang="html">
  <div class="wallet-detail p-wallet-detail">
    <CommonHeader class="header">
      <DiySelect
        v-model="currAction"
        :options="options"
        :placeholder="$t('wallet.detail')"
      />
    </CommonHeader>

    <JoLoadMore
      ref="loadmore"
      class="m-wallet-list"
      @onLoadMore="onLoadMore"
      @onRefresh="onRefresh"
    >
      <WalletDetailItem
        v-for="item in list"
        v-if="item.id"
        :key="item.id"
        :detail="item"
        @click="showDetail(item)"
      />
    </JoLoadMore>
  </div>
</template>

<script>
  import WalletDetailItem from './components/WalletDetailItem.vue'

  export default {
    name: 'WalletDetail',
    components: {
      WalletDetailItem,
    },
    data () {
      return {
        options: [
          { value: 'all', label: this.$t('all') },
          { value: 'expenses', label: this.$t('wallet.expenses') },
          { value: 'income', label: this.$t('wallet.income') },
        ],
        currAction: '',
        list: [],
        currInfo: null,
      }
    },
    computed: {
      after () {
        const last = this.list.slice(-1)[0]
        return last ? last.id : 0
      },
    },
    watch: {
      currAction () {
        this.list = []
        this.$refs.loadmore.beforeRefresh()
      },
    },
    methods: {
      showDetail (val) {
        this.$router.push({
          path: `/wallet/detail/${val.id}`,
          meta: { data: val },
        })
      },
      async onRefresh () {
        const data = await this.$store.dispatch('wallet/getWalletOrders', {
          action: this.currAction,
        })

        if (data.length > 0) this.list = data

        this.$refs.loadmore.afterRefresh(data.length < 15)
      },
      async onLoadMore () {
        const data = await this.$store.dispatch('wallet/getWalletOrders', {
          action: this.currAction,
          after: this.after,
        })
        if (data.length > 0) this.list = [...this.list, ...data]
        this.$refs.loadmore.afterLoadMore(data.length < 15)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-wallet-detail {
  .header {
    overflow: initial;
  }
}
</style>
