import { render, staticRenderFns } from "./Currency.vue?vue&type=template&id=1a4e483c&scoped=true&lang=html&"
import script from "./Currency.vue?vue&type=script&lang=js&"
export * from "./Currency.vue?vue&type=script&lang=js&"
import style0 from "./Currency.vue?vue&type=style&index=0&id=1a4e483c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a4e483c",
  null
  
)

export default component.exports