import { render, staticRenderFns } from "./CurrencyDetailItem.vue?vue&type=template&id=3566421a&scoped=true&"
import script from "./CurrencyDetailItem.vue?vue&type=script&lang=js&"
export * from "./CurrencyDetailItem.vue?vue&type=script&lang=js&"
import style0 from "./CurrencyDetailItem.vue?vue&type=style&index=0&id=3566421a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3566421a",
  null
  
)

export default component.exports