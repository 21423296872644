<template lang="html">
  <div class="p-currency-detail">
    <CommonHeader class="header">{{ $t('currency.detail') }}</CommonHeader>

    <JoLoadMore
      ref="loadmore"
      class="m-currency-list"
      @onLoadMore="onLoadMore"
      @onRefresh="onRefresh"
    >
      <CurrencyDetailItem
        v-for="item in list"
        v-if="item.id"
        :key="item.id"
        :nostyle="true"
        :detail="item"
        type="title"
      />
    </JoLoadMore>
  </div>
</template>

<script>
  import _ from 'lodash'
  import CurrencyDetailItem from './components/CurrencyDetailItem.vue'

  export default {
    name: 'CurrencyJournalDetail',
    components: { CurrencyDetailItem },
    data () {
      return {
        list: [],
        currInfo: null,
      }
    },
    computed: {
      after () {
        const len = this.list.length
        return len ? this.list[len - 1].id : 0
      },
      currAction: {
        get () {
          return this.$route.query.action || 'recharge'
        },
        set (val) {
          this.$router.replace({
            path: this.$route.path,
            query: { action: val },
          })
        },
      },
    },
    watch: {
      currAction () {
        this.list = []
        this.$refs.loadmore.beforeRefresh()
      },
    },
    methods: {
      async onRefresh () {
        const data = await this.$store.dispatch('currency/getCurrencyOrders')
        if (data.length > 0) this.list = _.unionBy([...data, ...this.list], 'id')

        this.$refs.loadmore.afterRefresh(data.length < 15)
      },
      async onLoadMore () {
        const data = await this.$store.dispatch('currency/getCurrencyOrders', {
          after: this.after,
        })
        if (data.length > 0) {
          this.list = [...this.list, ...data]
        }
        this.$refs.loadmore.afterLoadMore(data.length < 15)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-currency-detail {
  .header {
    overflow: initial;
  }

  .type-switch-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90px;

    > span {
      display: inline-block;
      height: 100%;
      padding: 22px 12px;
      color: #999;
      transition: 0.3s;

      &.active {
        color: #333;
        border-bottom: 2px solid @primary;
      }
    }
  }
}
</style>
