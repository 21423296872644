<template lang="html">
  <div class="p-currency-withdraw">
    <CommonHeader class="header">
      {{ $t('currency.withdraw.name') }}
      <RouterLink slot="right" :to="{path: '/currency/detail', query: { action: 'cash' } }">
        {{ $t('currency.withdraw.log') }}
      </RouterLink>
    </CommonHeader>

    <section class="m-currency-panel">
      <h3>{{ $t('currency.withdraw.title') }}</h3>
      <p>100{{ $t('currency.unit') }}=1.00{{ $t('wallet.unit') }}</p>
    </section>

    <main>
      <p>{{ $t('currency.withdraw.input') }}</p>
      <p>{{ $t('currency.withdraw.desc') }}</p>
      <div class="input-wrap">
        <input
          v-model="amount"
          :placeholder="$t('currency.withdraw.min', [cashMin])"
          type="number"
          oninput="value = value.slice(0,8)"
        >
      </div>

      <div class="m-lim-width" style="margin-top: 0.6rem">
        <button
          :disabled="disabled || loading"
          class="m-long-btn m-signin-btn"
          @click="handleOk"
        >
          <CircleLoading v-if="loading" />
          <span v-else>{{ $t('confirm') }}</span>
        </button>
      </div>
    </main>

    <footer>
      <p @click="popupRule">
        <svg class="m-style-svg m-svg-small">
          <use xlink:href="#icon-wallet-warning" />
        </svg>
        {{ $t('currency.withdraw.rule') }}
      </p>
    </footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'CurrencyWithdraw',
    data () {
      return {
        amount: '',
        loading: false,
      }
    },
    computed: {
      ...mapState({
        currency: state => state.CONFIG.currency,
        user: 'CURRENTUSER',
      }),
      cash () {
        return this.currency.cash
      },
      cashMin () {
        return this.currency.settings['cash-min'] || 1
      },
      cashMax () {
        return this.currency.settings['cash-max'] || 10
      },
      rule () {
        const rule = this.currency.cash.rule || ''
        return rule.replace(/\n/g, '<br>')
      },
      disabled () {
        return this.amount < this.cashMin || this.amount > this.cashMax
      },
      currentCurrency () {
        return this.user.currency.sum || 0
      },
    },
    created () {
      if (!this.currency.cash.status) {
        this.$Message.error(this.$t('currency.withdraw.disabled'))
        this.goBack()
      }
    },
    methods: {
      async handleOk () {
        // 积分不足时前往充值
        if (this.amount > this.currentCurrency) {
          this.$Message.error(this.$t('currency.insufficient'))
          return this.$router.push({ name: 'currencyRecharge' })
        }
        const { message } = await this.$store.dispatch('currency/requestWithdraw', this.amount)
        if (message) {
          this.$Message.success(message)
          this.goBack()
        }
      },
      selectWithdrawType () {
        const actions = []
        this.$bus.$emit('actionSheet', actions, this.$t('cancel'), this.$t('currency.withdraw.disabled'))
      },
      popupRule () {
        this.$bus.$emit('popupDialog', {
          title: `${this.$t('currency.withdraw.rule')}`,
          content: this.rule,
        })
      },
    },
  }
</script>

<style lang="less" scoped>
@import "./currency.less";

.p-currency-withdraw {
  .m-currency-panel p {
    font-size: 60px;
  }

  .m-entry {
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
  }

  main {
    font-size: 26px;
    padding: 30px;
    line-height: 54px;
    color: #999;

    .input-wrap {
      height: 90px;
      background-color: #f4f5f5;
      border: solid 1px #dedede; /* no */
      margin-top: 30px;

      input {
        background-color: transparent;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 30px;
      }
    }
  }
}
</style>
