<template lang="html">
  <div class="wallet-detail p-wallet-detail">
    <CommonHeader class="header"> {{ $t('wallet.withdraw.detail') }} </CommonHeader>

    <JoLoadMore
      ref="loadmore"
      class="m-wallet-list"
      @onLoadMore="onLoadMore"
      @onRefresh="onRefresh"
    >
      <WalletWithdrawDetailItem
        v-for="item in list"
        v-if="item.id"
        :key="item.id"
        :detail="item"
        @click="showDetail(item.id)"
      />
    </JoLoadMore>
  </div>
</template>

<script>
  import _ from 'lodash'
  import WalletWithdrawDetailItem from './components/WalletWithdrawDetailItem.vue'

  export default {
    name: 'WalletWithdrawDetail',
    components: { WalletWithdrawDetailItem },
    data () {
      return {
        currAction: 'out',
        list: [],
      }
    },
    computed: {
      after () {
        const len = this.list.length
        return len ? this.list[len - 1].id : 0
      },
    },
    watch: {
      currAction () {
        this.list = []
        this.$refs.loadmore.beforeRefresh()
      },
    },
    methods: {
      showDetail (id) {
        this.$router.push({ path: `/wallet/withdraw/detail/${id}` })
      },
      async onRefresh () {
        const data = await this.$store.dispatch('wallet/fetchWithdrawList')

        if (data.length > 0) this.list = _.unionBy([...data, ...this.list], 'id')

        this.$refs.loadmore.afterRefresh(data.length < 15)
      },
      async onLoadMore () {
        const data = await this.$store.dispatch('wallet/fetchWithdrawList', {
          after: this.after,
        })
        this.list.push(...data)
        this.$refs.loadmore.afterLoadMore(data.length < 15)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-wallet-detail {
  .header {
    overflow: initial;
  }
}
</style>
