<template>
  <div :class="isIncome" class="c-currency-detail-item">
    <div class="info">
      <div class="title" v-if="hasStatus">{{ stateTxt }}</div>
      <div class="title" v-else>{{ detail[type] }}</div>
      <div class="time">{{ detail.updated_at | time2tips }}</div>
    </div>
    <div class="amount" :class="detail.state == -1 ? 'state-fail' : ''" v-if="hasStatus">
      {{ detail.type > 0 ? '+' : '-' }}{{ detail.state == -1 ? 0 : detail.amount }}
    </div>
    <div class="amount" :class="detail.state == 0 ? 'state-wainning' : ''" v-else>
      {{ detail.type > 0 ? '+' : '-' }}{{ detail.amount }}
      <p class="state" v-if="detail.state == 0">等待</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CurrencyDetailItem',
    props: {
      detail: { type: Object, required: true },
      nostyle: { type: Boolean, default: false },
      type: { type: String, default: 'title' },
      hasStatus: { type: Boolean, default: false },
    },
    computed: {
      isIncome () {
        if (this.nostyle) return 'no-style'
        return this.detail.type > 0 || this.hasStatus ? 'in' : 'out'
      },
      stateTxt () {
        if (this.detail.state === 0) {
          return '正在审核'
        }
        if (this.detail.state === -1) {
          return '提取失败'
        }
        if (this.detail.state === 1) {
          return '提取成功'
        }

        return this.detail[this.type]
      },
    },
  }
</script>

<style lang="less" scoped>
.c-currency-detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ededed; /* no */
  color: #b3b3b3;
  padding: 30px;
  font-size: 30px;

  .info {
    max-width: calc(~"100% - 4em");

    .time {
      color: #b3b3b3;
      font-size: 24px;
    }
    .title {
      font-size: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
    .amount {
      font-size: 32px;
    }
  }

  &.no-style {
    color: #333;

    .amount {
      color: #ff9400;
    }
    .state-wainning {
      color: #b3b3b3;

      .state {
        font-size: 24px;
      }
    }
  }

  &.in {
    .title {
      color: #333;
    }

    .amount {
      color: #ff9400;
    }

    .state-fail {
      color: #b3b3b3;
    }
  }
}
</style>
