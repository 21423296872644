<template>
  <div class="c-wallet-detail-item" @click.stop="showDetail">
    <div class="time" v-html="created_at" />
    <div class="title">{{ detail.body || detail.title }}</div>
    <div class="amount">
      <span v-if="detail.state === 0" class="gray">{{ $t('wallet.review') }}</span>
      <span v-if="detail.state === 1">{{ detail.type > 0 ? '+' : '-' }}{{ (detail.amount / 100).toFixed(2) }}</span>
    </div>
  </div>
</template>

<script>
  import { timeOffset, toDateString } from '@/filters'
  import i18n from '@/i18n'

  const week = i18n.t('date.weeks')
  function splitYMD (date) {
    if (typeof date === 'string') date = date.replace(/-/g, '/') // for safari
    date = date || new Date()
    date = new Date(date)
    const Y = date.getFullYear()
    const M = date.getMonth() + 1
    const D = date.getDate()
    const w = week[date.getDay()]
    const h = (date.getHours() + '').padStart(2, 0)
    const m = (date.getMinutes() + '').padStart(2, 0)
    const d = (M + '').padStart(2, 0) + '.' + (D + '').padStart(2, 0)
    const t = h + ':' + m
    return { Y, M, D, w, d, t }
  }

  export default {
    name: 'WalletDetailItem',
    props: {
      detail: { type: Object, required: true },
    },
    data () {
      return {}
    },
    computed: {
      created_at () {
        const now = splitYMD(new Date())
        let time = new Date(toDateString(this.detail.created_at)).getTime()
        time = splitYMD(time)
        let D
        if (time.Y < now.Y) {
          D = time.d
        } else if (time.M < now.M) {
          D = time.d
        } else if (now.D - time.D > 1) {
          D = time.w
        } else if (now.D - time.D === 1) {
          D = this.$t('date.yesterday')
        } else if (now.D - time.D === 0) {
          D = this.$t('date.today')
        }
        return `<p>${D}</p><p>${time.d}</p>`
      },
    },
    methods: {
      showDetail () {
        this.$emit('click')
      },
    },
  }
</script>

<style lang="less" scoped>
.c-wallet-detail-item {
  padding: 30px;
  font-size: 30px;
  line-height: 36px;
  border-bottom: 1px solid #ededed; /* no */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .time {
    color: #b3b3b3;
    font-size: 24px;
    text-align: center;
    line-height: 1;
  }

  .title {
    margin: 0 30px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .amount {
    width: 5em;
    text-align: right;

    span {
      color: #ff9400;
      &.gray {
        color: #b3b3b3;
      }
    }
  }
}
</style>
