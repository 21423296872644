<template lang="html">
  <div class="p-wallet-withdraw">
    <CommonHeader>
      {{ $t('wallet.withdraw.name') }}
      <RouterLink
        slot="right"
        class="withdraw-detail"
        to="/wallet/withdraw/detail"
      >
        {{ $t('wallet.withdraw.detail') }}
      </RouterLink>
    </CommonHeader>

    <main class="m-box-model m-aln-center m-justify-center">
      <div class="m-box-model m-lim-width m-main">
        <div class="m-box m-aln-center m-justify-bet m-bb1 m-bt1 m-pinned-row plr20 m-pinned-amount-customize">
          <span>{{ $t('wallet.withdraw.amount') }}</span>
          <div class="m-box m-aln-center">
            <input
              v-model.number="amount"
              type="number"
              class="m-text-r"
              :placeholder="$t('wallet.withdraw.input')"
              oninput="value=value.slice(0,8)"
            >
            <span>{{ $t('wallet.unit') }}</span>
          </div>
        </div>
      </div>

      <div class="m-entry" @click="selectWithdrawType">
        <span class="m-text-box m-flex-grow1">{{ $t('wallet.withdraw.type.name') }}</span>
        <div class="m-box m-aln-end paid-type">{{ withdrawTypeText }}</div>
        <svg class="m-style-svg m-svg-def">
          <use xlink:href="#icon-arrow-right" />
        </svg>
      </div>

      <div class="m-box-model m-lim-width m-main">
        <div class="m-box m-aln-center m-justify-bet m-bb1 m-bt1 m-pinned-row plr20 m-pinned-amount-customize">
          <span>{{ $t('wallet.withdraw.account') }}</span>
          <div class="m-box m-aln-center">
            <input
              v-model="account"
              type="text"
              class="m-text-r"
              :placeholder="this.$t('wallet.withdraw.account_input')"
            >
          </div>
        </div>
      </div>

      <div class="plr20 m-lim-width" style="margin-top: 0.6rem">
        <button
          :disabled="disabled || loading"
          class="m-long-btn m-signin-btn"
          @click="handleOk"
        >
          <CircleLoading v-if="loading" />
          <span v-else>{{ $t('confirm') }}</span>
        </button>
      </div>
    </main>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import * as api from '@/api/wallet'
  import i18n from '@/i18n'

  const supportType = {
    alipay: { title: i18n.t('wallet.withdraw.type.alipay'), type: 'alipay' },
    wechat: { title: i18n.t('wallet.withdraw.type.wxpay'), type: 'wechat' },
  }

  export default {
    name: 'WalletWithdraw',
    data () {
      return {
        amount: '',
        account: '',
        selectedType: '',
        loading: false,
      }
    },
    computed: {
      ...mapState({
        wallet: state => state.CONFIG.wallet || {},
      }),
      disabled () {
        const { value, account, type } = this.form
        return !value || !account || !type
      },
      form () {
        const selectedType = supportType[this.selectedType] || {}
        return {
          value: this.amount * 100,
          type: selectedType.type,
          account: this.account,
        }
      },
      withdrawTypeText () {
        const selectedType = supportType[this.selectedType] || {}
        return selectedType.title
      },
      walletType () {
        return this.wallet.cash.types || []
      },
    },
    created () {
      if (!this.wallet.cash.status) {
        this.$Message.error(this.$t('wallet.withdraw.type.no'))
        this.goBack()
      }
    },
    methods: {
      handleOk () {
        if (this.amount !== ~~this.amount) return this.$Message.error(this.$t('wallet.withdraw.integer'))
        if (this.loading) return
        this.loading = true
        api.postWalletWithdraw(this.form)
          .then(({ data }) => {
            this.$Message.success(data.message)
            this.$router.replace('/wallet/withdraw/detail')
          })
          .finally(() => {
            this.loading = false
          })
      },
      selectWithdrawType () {
        const actions = []
        for (const key in supportType) {
          const type = supportType[key]
          if (this.walletType.includes(key)) {
            actions.push({
              text: type.title,
              method: () => {
                this.selectedType = key
              },
            })
          }
        }

        this.$bus.$emit(
          'actionSheet',
          actions,
          this.$t('cancel'),
          actions.length ? undefined : this.$t('wallet.withdraw.type.no'),
        )
      },
    },
  }
</script>

<style lang="less" scoped>
.m-entry {
  width: 100%;
  padding: 0 20px;
  background-color: #fff;
}

.paid-type {
  font-size: 30px;
  color: #999;
}

.withdraw-detail {
  width: 5em;
  text-align: right;
}
</style>
